import Highway from '@dogstudio/highway';
import {gsap} from 'gsap';
import $ from 'jquery';
import {Draggable} from 'gsap/Draggable';
gsap.registerPlugin(Draggable);

class DragNDrop extends Highway.Renderer {
	// Hooks/methods
	onEnter() {
        var $video = $(".video");
        function createDraggable() {
            Draggable.create(".tape", {
				onDrag: onDrag,
				onPress: onPress,
				onRelease: onRelease,
				zIndexBoost: false
			});

            function onDrag(){}
            function onPress(){}
            function onRelease() {
                if (this.hitTest(".player")) {
                    $video.children().attr("src", "https://player.vimeo.com/video/536659818?autoplay=1&loop=1&autopause=0");
                    gsap.to(this.target, {duration: 0.6, opacity: 0, scale: 0, 
                        onStart: function() {
                            gsap.to($(".interactive"), {duration: 1, opacity: 0});
                        }, 
                        onComplete: function() {
                            $(".interactive").hide();
                            $(".video").show();
                            gsap.to($(".video"), {duration: 2, opacity: 1});
                        }
                    });
                    
                    gsap.to($video, {duration: 0.6, opacity: 1});
                }
            }
        }

        createDraggable();
	}
	onLeave() {}
	onEnterCompleted() {
        if ($('#back').length) {
            $("#back").on("click", function() {
                window.history.back();
            });
        }
    }
	onLeaveCompleted() {}
}
export default DragNDrop;

  