import Highway from '@dogstudio/highway';
import $ from 'jquery';
import { fabric } from "fabric";
import HMS from "canvas-hms";
import { gsap } from 'gsap';
class Photobooth extends Highway.Renderer {
	// Hooks/methods
	onEnter() {
		const tw = window.innerWidth * 2;
		const th = window.innerHeight * 2;
		var pCanvas = document.getElementById('photoCanvas');
		var pContext = pCanvas.getContext('2d');
		var sCanvas = document.getElementById('stickerCanvas');
		var webcamVideo = document.getElementById('webcam');
		var output = document.getElementById('output');
		var facingMode = get("facingMode");
		var constraints = {
			audio: false,
			video: {
				facingMode: facingMode,
				width: {
					min: 640,
					ideal: 1280,
					max: 1920
				},
				height: {
					min: 360,
					ideal: 720,
					max: 1080
				}
			}
		};
		var vw;
		var vh;
		

		function startStreamedVideo() {

            webcamVideo.addEventListener("loadedmetadata", function() {
                vw = this.videoWidth;
                vh = this.videoHeight;
                pCanvas.width = vw;
                pCanvas.height = vh;
            }, false);

			if (facingMode == "user") {
				$('#webcam').css("transform", "scale(-1, 1)");
			}
			window.navigator.mediaDevices
				.getUserMedia(constraints)
				.then(function(stream) {
					// track = stream.getTracks()[0];
					webcamVideo.srcObject = stream;
					webcamVideo.play();
				})
				.catch(function(error) {
					console.error("Oops. Something is broken.", error);
					alert('You have to give browser the permission to access your webcam.');
				});
		}
		/*
		function stopStreamedVideo() {
		    const stream = webcamVideo.srcObject;
		    const tracks = stream.getTracks();

		    tracks.forEach(function(track) {
		        track.stop();
		    });

		    webcamVideo.srcObject = null;
		}
		*/
		function get(name) {
			if (name = (new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)')).exec(location.search)) {
				return decodeURIComponent(name[1]);
			}
			return "";
		}

		function isMobile() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				$("#change").hide();
				if (navigator.userAgent.match('CriOS')) {
					alert("Chrome in iOS is not supported for camera access!")
				}
			}
		}

		$("#snap").on("click", function() {
			pContext.resetTransform();
			if (facingMode == "user") {
				pContext.translate(pCanvas.width, 0);
				pContext.scale(-1, 1);
			}
			pContext.drawImage(webcamVideo, 0, 0, vw, vh);
			const options = {
				curveWidth: 765 / 3
			};
			const hms = new HMS({
				canvas: pCanvas,
				options
			});
			hms.setHighlights(20);
			hms.setMidtones(10);
			hms.setShadows(-15);
			hms.setTemperature(10);
			var imageDataURL = pCanvas.toDataURL('image/jpeg');
			sCanvas = new fabric.Canvas('stickerCanvas', {
				backgroundColor: 'white'
			});
			sCanvas.setWidth(tw);
			sCanvas.setHeight(th);
			var dw = window.innerWidth + 'px';
			var dh = window.innerHeight + 'px';
			sCanvas.setDimensions({
				width: dw,
				height: dh
			}, {
				cssOnly: true
			});
			var backgroundImg = new Image();
			backgroundImg.onload = function() {
				var fabricBackgroundImg = new fabric.Image(backgroundImg);
				fabricBackgroundImg.scaleToWidth(sCanvas.getWidth());
				var top = (sCanvas.getHeight() - (fabricBackgroundImg.height * fabricBackgroundImg.scaleY)) / 2;
				/*
				console.log(sCanvas.getHeight());
				console.log(fabricBackgroundImg.height);
				console.log(fabricBackgroundImg.scaleY);
				*/
				sCanvas.setBackgroundImage(fabricBackgroundImg, sCanvas.renderAll.bind(sCanvas), {
					top: top,
					left: 0
				});
				sCanvas.renderAll();
			};
			var photoFrameTopImg = document.querySelector('.frameTop');
			var frameTopImgInstance = new fabric.Image(photoFrameTopImg);
			frameTopImgInstance.selectable = false;
			frameTopImgInstance.scaleToWidth(sCanvas.width);
			frameTopImgInstance.set({
				top: 0
			});
			sCanvas.setOverlayImage(frameTopImgInstance, sCanvas.renderAll.bind(sCanvas));
			// var top = (sCanvas.getHeight() - (frameBotImgInstance.height * frameBotImgInstance.scaleY));
			backgroundImg.src = imageDataURL;
			// barFooter change
			$(".barFooter").children().eq(0).removeClass("bar");
			$(".barFooter").children().eq(0).addClass("none");
			$(".barFooter").children().eq(1).removeClass("none");
			$(".barFooter").children().eq(1).addClass("bar");
			$(".frame").hide();
			$("#photoTaking").hide();
			$("#editor").show();
		});
		$("#change").on("click", function() {
			if (facingMode == "user") {
				window.location.href = "photobooth.html?facingMode=environment";
			} else {
				window.location.href = "photobooth.html?facingMode=user";
			}
		});
		$("#retake").on("click", function() {
			sCanvas.dispose();
			$(sCanvas.wrapperEl).remove();
			$(".barFooter").children().eq(2).removeClass("bar");
			$(".barFooter").children().eq(2).addClass("none");
			$(".barFooter").children().eq(0).removeClass("none");
			$(".barFooter").children().eq(0).addClass("bar");
			$(".frame").show();
			$("#photoTaking").show();
			$("#editor").hide();
			$("#result").hide();
		});
		$("#upload").on("click", function() {
			let fileSelect = $('#fileSelect');
			fileSelect.on("change", function(evt) {
				var f = evt.target.files[0];
				var fileReader = new FileReader();
				fileReader.onload = function(e) {
					sCanvas = new fabric.Canvas('stickerCanvas', {
						backgroundColor: 'white'
					});
					sCanvas.setWidth(tw);
					sCanvas.setHeight(th);
					var dw = window.innerWidth + 'px';
					var dh = window.innerHeight + 'px';
					sCanvas.setDimensions({
						width: dw,
						height: dh
					}, {
						cssOnly: true
					});
					var uploadImg = new Image();
					var backgroundImg = new Image();
					uploadImg.onload = function() {
						if (uploadImg.width > uploadImg.height) {
							pCanvas.width = uploadImg.height;
							pCanvas.height = uploadImg.width;
							pContext.resetTransform();
							pContext.setTransform(
								0, 1, // x axis down the screen
								-1, 0, // y axis across the screen from right to left
								uploadImg.height, // x origin is on the right side of the canvas
								0 // y origin is at the top
							);
							pContext.drawImage(uploadImg, 0, 0);
							pContext.setTransform(1, 0, 0, 1, 0, 0);
						} else {
							pCanvas.width = uploadImg.width;
							pCanvas.height = uploadImg.height;
							pContext.drawImage(uploadImg, 0, 0, pCanvas.width, pCanvas.height);
						}
						backgroundImg.src = pCanvas.toDataURL()
					};
					backgroundImg.onload = function() {
						var ratio = tw / backgroundImg.width;
						var top = (th - (backgroundImg.height * ratio)) / 2;
						var fabricBackgroundImg = new fabric.Image(backgroundImg);
						fabricBackgroundImg.scaleToWidth(sCanvas.width);
						sCanvas.setBackgroundImage(fabricBackgroundImg, sCanvas.renderAll.bind(sCanvas), {
							top: top,
							left: 0
						});
						sCanvas.renderAll();
						$(".overlay").hide();
					};
					var photoFrameTopImg = document.querySelector('.frameTop');
					var frameTopImgInstance = new fabric.Image(photoFrameTopImg);
					frameTopImgInstance.selectable = false;
					frameTopImgInstance.scaleToWidth(sCanvas.width);
					frameTopImgInstance.set({
						top: 0
					});
					sCanvas.setOverlayImage(frameTopImgInstance, sCanvas.renderAll.bind(sCanvas));
					uploadImg.src = e.target.result;
					$(".overlay").show();
					$("#photoTaking").hide();
					$("#editor").show();
					$(".barFooter").children().eq(0).removeClass("bar");
					$(".barFooter").children().eq(0).addClass("none");
					$(".barFooter").children().eq(1).removeClass("none");
					$(".barFooter").children().eq(1).addClass("bar");
				};
				if (evt.target.files[0]) {
					fileReader.readAsDataURL(f);
				}
				this.value = '';
			});
			fileSelect.click();
		});
		$("#delete").on("click", function() {
			if (sCanvas.getActiveObject()) {
				sCanvas.remove(sCanvas.getActiveObject());
			}
		})
		$("#sticker").on("click", function(e) {
			$("#stickerContainer").css("display", "flex");
			$(".barFooter").hide();
		});
		$(".sticker").on("click", function() {
			var stickerEl = document.getElementById($(this).attr('id'));
			var stickerInstance = new fabric.Image(stickerEl, {
				left: 300,
				top: 300
			})
			stickerInstance.scale(1);
			stickerInstance.setControlsVisibility({
				mt: false,
				mb: false,
				ml: false,
				mr: false,
				bl: true,
				br: true,
				tl: true,
				tr: true,
				mtr: true,
			});
			sCanvas.add(stickerInstance).setActiveObject(stickerInstance);
			stickerInstance.set({
				borderColor: 'red',
				cornerColor: 'green',
				cornerSize: 20,
				transparentCorners: false
			});
			$("#stickerContainer").hide();
			$(".barFooter").show();
		});
		$("#confirm").on("click", function() {
			sCanvas.discardActiveObject().renderAll();
			var imageDataURL = sCanvas.toDataURL({
				width: sCanvas.width,
				height: sCanvas.height,
				left: 0,
				top: 0,
				format: 'jpeg',
				quality: 1.0
			});
			output.src = imageDataURL;
			$("#editor").hide();
			$("#result").show();
			$(".barFooter").children().eq(1).removeClass("bar");
			$(".barFooter").children().eq(1).addClass("none");
			$(".barFooter").children().eq(2).removeClass("none");
			$(".barFooter").children().eq(2).addClass("bar");
		});
		$("#download").on("click", function() {
			var element = document.createElement('a');
			var base64 = sCanvas.toDataURL({
				format: 'image/jpeg'
			});
			var clearUrl = base64.replace(/^data:image\/\w+;base64,/, '');
			element.target = "_blank";
			// element.setAttribute('href', 'data:attachment/image' + base64);
			element.setAttribute('href', 'data:application/octet-stream;base64,' + encodeURIComponent(clearUrl));
			var d = new Date();
			var n = d.getTime();
			element.setAttribute('download', n + '.jpg');
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
		});
		/*
		$("#share").on("click", async function(){
		    var base64 = sCanvas.toDataURL({format: 'image/jpeg'})
		    var blob = await (await fetch(base64)).blob();
		    var d = new Date();
		    var n = d.getTime();
		    var file = new File([blob], n + '.jpg', { type: blob.type });
		    if (navigator.canShare && navigator.canShare({ files: [file] })) {
		        navigator.share({
		          files: [file],
		          title: 'Chinachem Group - 60th Anniversary - Reviving Collective Memories',
		          text: 'We do not have any time machine to bring you back to the past, yet we can still project scenes from the old days to the present and let you personally experience the progressive changes of our city across past decades.',
		        })
		        .then(() => console.log('Share was successful.'))
		        .catch((error) => console.log('Sharing failed', error));
		    } else {
		    console.log(`Your system doesn't support sharing files.`);
		    }
		});
		*/
		$( window ).on( "resize", function( event ) {
			location.reload();
			window.scrollTo(0, 0);
		});
		startStreamedVideo();
		isMobile();
	}
	onLeave() {}
	onEnterCompleted() {
        function initializedPage(){
            $(".overlay").hide();
            gsap.to(".loading", {
                rotation: 360,
                duration: 0.8,
                repeat: -1,
                yoyo: false
            });
            if ($('#back').length) {
                $("#back").on("click", function() {
                    window.history.back();
                });
            }
        }

        initializedPage();
    }
	onLeaveCompleted() {}
}
export default Photobooth;