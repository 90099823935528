import Highway from '@dogstudio/highway';
import {gsap} from 'gsap';
import $ from 'jquery';
import Splide from '@splidejs/splide';
import Video from '@splidejs/splide-extension-video';

class Slideshow extends Highway.Renderer {
	// Hooks/methods
	onEnter() {
        var hr = document.title.indexOf("Neighbourhood") !== -1 ? 1.25 : document.title.indexOf("Flourishing") !== -1 ? 1.1 : isMobile();
        var c = document.title.indexOf("Neighbourhood") !== -1 ? false : document.title.indexOf("Flourishing") !== -1 ? false : true;
        var p = 1;
        var f = 0;
        var splides = [];
        
        function resize() {
            if ($(window).width() > 768) {
                if (document.title.indexOf("Flourishing") !== -1) {
                    p = 3;
                    hr = 0.4;
                    c = false;
                    f = 'center';
                }
            }
        }

        function isMobile() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return 0.5625;
			}
            return 0.4;
		}

        function stopVideo() {
            var iframe = document.querySelector( 'iframe');
            var video = document.querySelector( 'video' );
            if ( iframe ) {
                var iframeSrc = iframe.src;
                iframe.src = iframeSrc;
            }
            if ( video ) {
                video.pause();
            }
        };

        function createSplide(){
            var splide = new Splide( '#splide', {
                type: 'loop',
                focus: f,
                heightRatio: hr,
                cover : c,
                lazyLoad : 'sequential',
                perPage: p,
                video : {
                    loop: true,
                    autoplay: true
                }
            } ).mount( { Video } );

            splide.on('moved', function(n, o){
                if (n != o) {
                    stopVideo();
                    if ($(".textContent").length) {
                        var $textContent = $(".textContent");
                        gsap.to($textContent.eq(o), {duration: 0.2, opacity: 0, onComplete: function() {
                            $textContent.eq(o).hide();
                            $textContent.eq(n).show();
                            gsap.to($textContent.eq(n), {duration: 0.2, opacity: 1});
                        }});
                    }
                }
                /*
                $('html, body').animate({
                    scrollTop: 0
                }, 'slow');
                */
            })
            
            if($('.splide').length > 1) {
                var elms = document.getElementsByClassName( 'splide' );
                for ( var i = 1, len = elms.length; i < len; i++ ) {
                    var newSplide = new Splide( elms[ i ] , {
                        type : 'loop',
                        autoWidth: true,
                        autoHeight: true,
                        cover: false,
                        lazyLoad : 'sequential',
                        video : {
                            loop: true,
                            autoplay: true
                        }
                    }).mount( { Video });
                    splides.push(newSplide);
                }
            }
        }

        if ($('#splide').length) {
            resize();
            createSplide();
        }

        if ($('.play').length) {
            $( ".play" ).each(function( index ) {
                gsap.to($(this), {rotation: 20, duration: 0.2, ease: "circ.inOut", repeat: -1, yoyo: true});
            });

            $( ".play" ).on("click", function() {
                const area = $(this).parent().attr('class');
                gsap.to(".overlay", {
                    opacity: 1, 
                    duration: 0.2,
                    onStart: function() {
                        console.log(area);
                        switch(area) {
                            case "area1":
                                $(".video").children().attr("src", "https://player.vimeo.com/video/544950169?autoplay=1&loop=1&autopause=0");
                                break;
                            case "area2":
                                $(".video").children().attr("src", "https://player.vimeo.com/video/544950127?autoplay=1&loop=1&autopause=0");
                                break;
                            case "area3":
                                $(".video").children().attr("src", "https://player.vimeo.com/video/544950210?autoplay=1&loop=1&autopause=0");
                                break;
                            case "area4":
                                $(".video").children().attr("src", "https://player.vimeo.com/video/544950249?autoplay=1&loop=1&autopause=0");
                                break;
                            case "area5":
                                $(".video").children().attr("src", "https://player.vimeo.com/video/544950288?autoplay=1&loop=1&autopause=0");
                                break;
                        }
                        $(".overlay").show();
                        //$(".overlay").addClass("center");
                    }
                });
                
            });
        }

        if ($('#close').length) {
            $( "#close" ).on("click", function() {
                // window.history.back();
                gsap.to(".overlay", {
                    opacity: 0, 
                    duration: 0.5, 
                    onComplete: function() {
                        $(".video").children().attr("src", null);
                        /*
                        $(".overlay").removeClass("center");
                        $(".overlay").addClass("hide");
                        */
                        $(".overlay").hide();
                    }
                });
            });
        }

        if ($('.downArrow').length) {
            gsap.to(".downArrow", {
                y: "-50%",
                duration: 0.5,
                repeat: -1,
                yoyo: true
            })

            $( ".downArrow" ).on("click", function() {
                $('html, body').animate({
                    scrollTop: document.body.scrollHeight
                 }, 'slow');
            });
        }

        if ($('.switch').length) {
            gsap.to($('.switch'), {rotationZ: 60, duration: 0.5, repeat: -1, yoyo: true});

            $(".switch").on("click", function() {
                gsap.to("#television", {
                    opacity: 0, 
                    duration: 0.5, 
                    onComplete: function() {
                        $("#television").hide();
                        gsap.to($(".result"), {opacity: 1, duration: 0.5});
                    }
                });
            });
        }

        if ($('#back').length) {
            $("#back").on("click", function() {
                window.history.back();
            });
        }
	}
	onLeave() {}
	onEnterCompleted() {}
	onLeaveCompleted() {}
}
export default Slideshow;

  