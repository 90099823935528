import Highway from '@dogstudio/highway';
import $ from 'jquery';
import {gsap} from 'gsap';
import {TimelineMax} from 'gsap';

class Fade extends Highway.Transition{
    in({from, to, done}){
        if (to.className == "home") {
            console.log(localStorage.getItem("lastScroll"));
            $('html, body').animate({
                scrollTop: localStorage.getItem("lastScroll")
            }, 'slow');
        }

        if (from.className == "home") {
            let lastScrollPos = document.body.scrollTop ;
            localStorage.setItem("lastScroll", lastScrollPos);
            $('html, body').animate({
                scrollTop: 0
            }, 'slow');
        }

        gsap.fromTo(to.children[0], {
            duration: 2, 
            opacity: 0, 
            onComplete: function(){
                from.remove();

                if ($("section").attr("class") == "home") {
                    $( ".light" ).each(function( index ) {
                        gsap.to($(this), {opacity: 0, duration: 0.2 + Math.random(), repeat: -1, yoyo: true});
                    });
                    
                    $( ".robot" ).each(function( index ) {
                        gsap.to($(this), {y: "-10%", duration: 0.2 + Math.random(), repeat: -1, yoyo: true});
                    });

                    $( ".wall" ).each(function( index ) {
                        gsap.to($(this), {opacity: index, duration: 4, repeat: -1, yoyo: true, repeatDelay: 2});
                    });

                    $( ".tv" ).each(function( index ) {
                        gsap.to($(this), {opacity: 0, duration: 1 + Math.random(), repeat: -1, yoyo: true, repeatDelay: 2});
                    });

                    gsap.set($(".panelBack"), {rotationY:-180});

                    $( ".panelContent" ).each(function( index ) {
                    
                        var frontCard = $(this).children(".panelFront"),
                            backCard = $(this).children(".panelBack"),
                            tl = new TimelineMax();
                        
                        tl
                        .to(frontCard, {duration: 1, rotationY: 180})
                        .to(backCard, {duration: 1, rotationY: 0, delay: -1})
                        .to(frontCard, {duration: 1, rotationY: 0, delay: 4})
                        .to(backCard, {duration: 1, rotationY: -180, delay: -1})
                        .repeat(-1)
                        .delay(index * 0.25)
                        .repeatDelay(4);
                    });
                }

                done();
            }}, {duration: 2, opacity: 1});
    }
    out({from, done}){
        done();
    }
}

export default Fade;