import Highway from '@dogstudio/highway';
import {gsap} from 'gsap';
import $ from 'jquery';

class Video extends Highway.Renderer {
	// Hooks/methods
	onEnter() {

        if ($('.play').length) {
            $( ".play" ).each(function( index ) {
                gsap.to($(this), {rotation: 20, duration: 0.2, ease: "circ.inOut", repeat: -1, yoyo: true});
            });

            $( ".play" ).on("click", function() {
                const area = $(this).parent().attr('class');
                gsap.to(".overlay", {
                    opacity: 1, 
                    duration: 0.2,
                    onStart: function() {
                        console.log(area);
                        switch(area) {
                            case "area1":
                                $(".video").children().attr("src", "https://player.vimeo.com/video/544950169?autoplay=1&loop=1&autopause=0");
                                break;
                            case "area2":
                                $(".video").children().attr("src", "https://player.vimeo.com/video/544950127?autoplay=1&loop=1&autopause=0");
                                break;
                            case "area3":
                                $(".video").children().attr("src", "https://player.vimeo.com/video/544950210?autoplay=1&loop=1&autopause=0");
                                break;
                            case "area4":
                                $(".video").children().attr("src", "https://player.vimeo.com/video/544950249?autoplay=1&loop=1&autopause=0");
                                break;
                            case "area5":
                                $(".video").children().attr("src", "https://player.vimeo.com/video/544950288?autoplay=1&loop=1&autopause=0");
                                break;
                        }
                        $(".overlay").show();
                        //$(".overlay").addClass("center");
                    }
                });
                
            });
        }

        if ($('#close').length) {
            $( "#close" ).on("click", function() {
                // window.history.back();
                gsap.to(".overlay", {
                    opacity: 0, 
                    duration: 0.5, 
                    onComplete: function() {
                        $(".video").children().attr('src', '');
                        /*
                        $(".overlay").removeClass("center");
                        $(".overlay").addClass("hide");
                        */
                        $(".overlay").hide();
                    }
                });
            });
        }
	}
	onLeave() {}
	onEnterCompleted() {}
	onLeaveCompleted() {}
}
export default Video;

  