import Highway from '@dogstudio/highway';
import $ from 'jquery';
import {gsap} from 'gsap';
import {Draggable} from 'gsap/Draggable';
gsap.registerPlugin(Draggable);
class Puzzle extends Highway.Renderer {
	// Hooks/methods
	onEnter() {
		let puzzles = $(".puzzle");
		let silhouettes = $(".silhouette");
		let hitCount = 0;
		const overlapThreshold = "30%"; 

		const silhouette1PosX = 0;
		const silhouette1PosY = $(window).width() * 7 / 75;
		const silhouette2PosX = $(window).width() * 56 / 75;
		const silhouette2PosY = $(window).width() * 13 / 75;
		const puzzle1PosX = 0;
		const puzzle1PosY = $(window).width() * 17 / 25;
		const puzzle2PosX = $(window).width() * 56 / 75;
		const puzzle2PosY = $(window).width() * 19 / 25;

		if ($(window).width() > 768) {
			gsap.set("#silhouette1", {x: silhouette1PosX / 4, y: silhouette1PosY / 4});
			gsap.set("#silhouette2", {x: silhouette2PosX / 4, y: silhouette2PosY/ 4});
			gsap.set("#puzzle1", {x: puzzle1PosX / 4, y: puzzle1PosY / 4});
			gsap.set("#puzzle2", {x: puzzle2PosX / 4, y: puzzle2PosY / 4});
		}
		else {
			gsap.set("#silhouette1", {x: silhouette1PosX, y: silhouette1PosY});
			gsap.set("#silhouette2", {x: silhouette2PosX, y: silhouette2PosY});
			gsap.set("#puzzle1", {x: puzzle1PosX, y: puzzle1PosY});
			gsap.set("#puzzle2", {x: puzzle2PosX, y: puzzle2PosY});
		}
		

		Draggable.create(silhouettes, {
			dragResistance: 1
		});

		Draggable.create(puzzles, {
			onPress: function() {
				this.startX = gsap.getProperty(this.target, "x"); 
				this.startY = gsap.getProperty(this.target, "y");
			},
			onRelease: function(e) {
				  let i = silhouettes.length;
				  let isHit = false; 
				  
				  while (--i > -1) {
					  if (this.hitTest(silhouettes[i], overlapThreshold)) {
						  var id = gsap.getProperty(this.target, "id");
						  console.log("Target: " + id.substr(id.length - 1));
						  console.log("Dest: " + (i + 1));
						  if(id.substr(id.length - 1) == (i + 1)) {
							var destination = silhouettes[i];
							gsap.to(this.target, {duration: 0.2, x: gsap.getProperty(destination, "x"), y: gsap.getProperty(destination, "y")});
							gsap.to(destination, {duration: 0.2, opacity: 0});
							isHit = true;
							hitCount++;
						  }
						}
				  }

				  if (!isHit) {
					gsap.to(this.target, {duration: 0.2, x: this.startX, y: this.startY});
				  }

				  checkCompleted();
			  }
		});
		
		function checkCompleted() {
			if (hitCount == 2) {
				$(".video").children().attr("src", "https://player.vimeo.com/video/539992055?autoplay=1&loop=1&autopause=0");
				gsap.to($(".interactive"), {duration: 2, opacity: 0, onComplete: function() {
					$(".interactive").hide();
					$(".video").show();
					gsap.to($(".video"), {duration: 2, opacity: 1});
				}});
			}
		}
	}
	onLeave() {}
	onEnterCompleted() {
		if ($('#back').length) {
            $( "#back" ).on("click", function() {
                window.history.back();
            });
        }
	}
	onLeaveCompleted() {}
}
export default Puzzle;