import Highway from '@dogstudio/highway';
import Fade from './transition';
import Puzzle from './puzzle';
import DragNDrop from './dragndrop';
import SlideShow from './slideshow';
import Photobooth from './photobooth';
import Video from './video';
import 'regenerator-runtime/runtime';
import {gsap} from 'gsap';
import {TimelineMax} from 'gsap';
import $ from 'jquery';

console.clear();

const H = new Highway.Core({
    transitions: {
        default: Fade
    },
    renderers: {
        "shaping": Puzzle,
        "evolving": DragNDrop,
        "photobooth": Photobooth,
        "forging": SlideShow,
        "inspiring": SlideShow,
        "time-travelling": SlideShow,
        "neighbourhood": SlideShow,
        "flourishing": SlideShow,
        "creating": Video,
        "reviving": SlideShow,
        "collective": SlideShow,
        "disclaimer": SlideShow,
        "privacy": SlideShow,
        "contact": SlideShow,
        "commercial": SlideShow,
        "industrial": SlideShow,
        "improvement": SlideShow,
    }
});

if ($("section").attr("class") == "home") {
    $( ".light" ).each(function( index ) {
        gsap.to($(this), {opacity: 0, duration: 0.2 + Math.random(), repeat: -1, yoyo: true});
    });
    
    $( ".robot" ).each(function( index ) {
        gsap.to($(this), {y: "-10%", duration: 0.2 + Math.random(), repeat: -1, yoyo: true});
    });
    
    $( ".wall" ).each(function( index ) {
        gsap.to($(this), {opacity: index, duration: 4, repeat: -1, yoyo: true, repeatDelay: 2});
    });
    
    $( ".tv" ).each(function( index ) {
        gsap.to($(this), {opacity: 0, duration: 1 + Math.random(), repeat: -1, yoyo: true, repeatDelay: 2});
    });
    
    gsap.set($(".panelBack"), {rotationY:-180});
    
    $( ".panelContent" ).each(function( index ) {
      
        var frontCard = $(this).children(".panelFront"),
            backCard = $(this).children(".panelBack"),
            tl = new TimelineMax();
        
        tl
          .to(frontCard, {duration: 1, rotationY: 180})
          .to(backCard, {duration: 1, rotationY: 0, delay: -1})
          .to(frontCard, {duration: 1, rotationY: 0, delay: 4})
          .to(backCard, {duration: 1, rotationY: -180, delay: -1})
          .repeat(-1)
          .delay(index * 0.25)
          .repeatDelay(4);
    });
}


$(function() {

    $( window ).on( "orientationchange", function( event ) {
        location.reload();
        window.scrollTo(0, 0);
    });
    /*
    $( window ).on( "resize", function( event ) {
        location.reload();
        window.scrollTo(0, 0);
    });
    */
});

